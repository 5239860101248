import type { Palette, PaletteColor } from '@mui/material';
import type { ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

import { COMPANY_BANK_ACCOUNT_STATUS_ENUM } from '@woovi/shared';
import { Tag } from '@woovi/ui';

import type { UserMenuCompanyBankAccountStatus_account$key } from './__generated__/UserMenuCompanyBankAccountStatus_account.graphql.ts';

type UserMenuCompanyBankAccountStatusProps = {
  account: UserMenuCompanyBankAccountStatus_account$key;
};

const UserMenuCompanyBankAccountStatus = ({
  account,
}: UserMenuCompanyBankAccountStatusProps): ReactNode => {
  const query = useFragment<UserMenuCompanyBankAccountStatus_account$key>(
    graphql`
      fragment UserMenuCompanyBankAccountStatus_account on CompanyBankAccount {
        status
      }
    `,
    account,
  );

  const tagProps = {
    [COMPANY_BANK_ACCOUNT_STATUS_ENUM.CLOSED]: {
      label: 'Closed',
      theme: 'error' as keyof Palette,
    },
    [COMPANY_BANK_ACCOUNT_STATUS_ENUM.BLOCKED]: {
      label: 'Blocked',
      theme: 'warning' as keyof Palette,
    },
  }[query?.status || ''];

  return tagProps ? (
    <Tag
      variant='filled'
      label={tagProps.label}
      sx={{
        color: 'white',
        backgroundColor: (theme) =>
          (theme.palette[tagProps.theme] as PaletteColor).light,
      }}
    />
  ) : null;
};

export default UserMenuCompanyBankAccountStatus;
